import { styled } from "@mui/material/styles"
import { SpinnerGap } from "@phosphor-icons/react"

const Spinner = styled(SpinnerGap)(({ theme }) => ({
	animation: "spinner infinite 1500ms linear",
	fontSize: "1.5rem",
	color: theme.palette.grey[800],

	"@keyframes spinner": {
		from: {
			transform: "rotate(0deg)",
		},
		to: {
			transform: "rotate(360deg)",
		},
	},
}))

export default Spinner

import TextField, { getMaxCharacterMutationFn, trim } from "@/components/TextField"
import { useAboutUsValues } from "@/hooks"
import { builderErrorStateAtom, EditItem, formControlAtom } from "@/state"
import Box from "@mui/material/Box"
import { useAtomValue, useSetAtom } from "jotai"
import React from "react"

const maxCharacterCount = 200
const blurMutationFns = [trim]
const changeMutationFns = [getMaxCharacterMutationFn(maxCharacterCount)]

const ofMaxCharactersText = (currentLength: number) => `Max characters ${currentLength}/${maxCharacterCount}`

export const About = () => {
	const setErrorAtom = useSetAtom(builderErrorStateAtom)

	const { aboutUs } = useAboutUsValues(true)

	const { handleOnChange, fieldErrors } = useAtomValue(formControlAtom)

	const maxCharactersHelperText = ofMaxCharactersText((aboutUs as string)?.length ?? 0)

	React.useEffect(() => {
		setErrorAtom((prev) => ({ ...prev, [EditItem.AboutUs]: !!fieldErrors.aboutUs?.message }))
	}, [fieldErrors.aboutUs])

	return (
		<Box p="1rem">
			<TextField
				name="about"
				variant="filled"
				label="Enter about us (optional)"
				/**
				 * Using the autofocus prop places the cursor at the beginning of the
				 * TextField. This is a workaround to have the cursor be placed at the end
				 */
				inputRef={(input) => input?.focus()}
				onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
				multiline
				helperText={fieldErrors.aboutUs?.message ?? maxCharactersHelperText}
				onChange={(e) => {
					const update = handleOnChange("aboutUs")
					update(e.target.value)
				}}
				value={aboutUs}
				fullWidth
				error={fieldErrors.aboutUs?.message}
				size="medium"
				blurMutationFns={blurMutationFns}
				changeMutationFns={changeMutationFns}
				minRows={4}
			/>
		</Box>
	)
}

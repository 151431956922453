"use client"

import { PreviewType } from "@/enums"
import { previewTypeAtom } from "@/state"
import createCache, { EmotionCache } from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
import Stack from "@mui/material/Stack"
import { useAtomValue } from "jotai"
import React from "react"
import ReactDOM from "react-dom"
import TemplateComponentThemeProvider from "../Template/TemplateThemeProvider"

const dimensionsMap = {
	[PreviewType.Desktop]: { width: "100%", height: "100%" },
	[PreviewType.Mobile]: { width: "375px", height: "667px" },
}

const getFontTags = () => {
	const fontTag1 = document.createElement("link")
	fontTag1.rel = "preconnect"
	fontTag1.href = "https://fonts.googleapis.com"

	const fontTag2 = document.createElement("link")
	fontTag2.rel = "preconnect"
	fontTag2.href = "https://fonts.gstatic.com"
	fontTag2.crossOrigin = ""

	const fontTag3 = document.createElement("link")
	fontTag3.rel = "stylesheet"
	fontTag3.href = "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap"

	return [fontTag1, fontTag2, fontTag3]
}

export const IframeComponent = (props: React.PropsWithChildren) => {
	const iframeRef = React.useRef<HTMLIFrameElement | null>(null)
	const [iframeDocument, setIframeDocument] = React.useState<Document | undefined>(undefined)
	const previewType = useAtomValue(previewTypeAtom)
	const dimensions = dimensionsMap[previewType]
	const [emotionCache, setEmotionCache] = React.useState<EmotionCache>()

	React.useEffect(() => {
		if (iframeRef?.current !== null) {
			const doc = iframeRef.current.contentWindow?.document!
			setIframeDocument(doc)

			getFontTags().forEach((fontTag) => {
				doc.head.appendChild(fontTag)
			})

			// Ensure that the iframe has the same head content as the parent document
			const headElement = document.head
			doc.head.innerHTML = headElement.innerHTML
			document.body.querySelectorAll("script").forEach((script) => {
				doc.body.appendChild(script.cloneNode(true))
			})

			/**
			 * CacheProvider element: The CacheProvider from @emotion/react allows you to specify where
			 * the Emotion styles should be injected. By creating a custom cache and
			 * setting the container to the iframe’s document head, you ensure that the styles
			 * are correctly applied within the iframe.
			 * createCache: The createCache function creates a new Emotion cache, targeting the iframe’s document head.
			 * This ensures that the styles are injected into the correct document.
			 */
			setEmotionCache(
				createCache({
					key: "preview", // Unique to prevent conflicts with other caches
					container: doc.head,
				}),
			)

			setIframeDocument(doc)
		}
	}, [])

	return (
		<Stack
			sx={{
				alignItems: "center",
				width: "100%",
			}}
		>
			<iframe
				title="Preview"
				ref={iframeRef}
				style={{
					width: `${dimensions.width}`,
					height: `${dimensions.height}`,
					border: "none",
					transition: "width 0.3s ease-in-out",
				}}
			>
				{iframeDocument &&
					emotionCache &&
					ReactDOM.createPortal(
						<CacheProvider value={emotionCache}>
							<TemplateComponentThemeProvider>{props.children}</TemplateComponentThemeProvider>,
						</CacheProvider>,
						iframeDocument.body,
					)}
			</iframe>
		</Stack>
	)
}

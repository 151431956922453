import { theme } from "@ikhokha/commons-ui/build/dist/cjs/theme"
import { Box } from "@mui/material"
import { MuiTelInput, MuiTelInputContinent, MuiTelInputInfo } from "mui-tel-input"
import React, { FC } from "react"
import flags from "react-phone-number-input/flags"
import { Country } from "react-phone-number-input/input"

export type Flag = (typeof flags)["ZA"]

interface ContactNumberInputProps {
	value: string
	onChange: (_contact: string, _flagIcon?: Flag, _callingCode?: string, _country?: string, _ISO?: Country) => void
	label: string
	required: boolean
	hasErrors: boolean
	validationText?: string
	onBlur?: () => void
	validate?: boolean
}

export const ContactNumber: FC<ContactNumberInputProps> = ({
	value,
	hasErrors,
	validationText,
	onBlur,
	onChange,
	validate,
	required,
	label,
}) => {
	const [contact, setContact] = React.useState<string>(value)
	const continents: MuiTelInputContinent[] = ["AF"]

	const handleChange = (newValue: string, info: MuiTelInputInfo) => {
		if (!required && newValue.trim() === "") {
			setContact(newValue)
			onChange(newValue, undefined, undefined, undefined, undefined) // valid is true for empty optional number
			return
		}

		setContact(newValue)
		onChange(newValue, undefined, undefined, undefined, undefined)
	}

	return (
		<Box>
			<MuiTelInput
				value={contact}
				onBlur={onBlur}
				onChange={handleChange}
				continents={continents}
				inputProps={{ maxLength: 10 }}
				label={`${label} ${required ? "*" : "(optional)"}`}
				InputLabelProps={{
					style: {
						fontSize: "16px",
						fontWeight: "400",
						letterSpacing: "0.15px",
						lineHeight: "24px",
						color: theme.palette.text.secondary,
					},
				}}
				defaultCountry="ZA"
				forceCallingCode
				preferredCountries={["ZA"]}
				disableFormatting
				error={validate && hasErrors}
				helperText={validate && hasErrors && validationText}
			/>
		</Box>
	)
}

import { useScrollToComponent } from "@/hooks"
import { builderErrorStateAtom, EditItem } from "@/state"
import { palette } from "@ikhokha/commons-ui/build/dist/cjs/palette"
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline"
import CircleOutlined from "@mui/icons-material/CircleOutlined"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Typography from "@mui/material/Typography"
import { useAtomValue } from "jotai"

type Item = { text: string; editItem: EditItem }

const items: Item[] = [
	{
		text: "Upload logo",
		editItem: EditItem.Logo,
	},
	{
		text: "Add products and services",
		editItem: EditItem.CatalogueItems,
	},
	{
		text: "Change banner image",
		editItem: EditItem.HeroImage,
	},
	{
		text: "Edit banner text",
		editItem: EditItem.HeroContent,
	},
	{
		text: "Confirm colour styles",
		editItem: EditItem.Colours,
	},
	{
		text: "Edit About Us",
		editItem: EditItem.AboutUs,
	},
	{
		text: "Add social media details",
		editItem: EditItem.Socials,
	},
	{
		text: "Add Store Details*",
		editItem: EditItem.StoreDetails,
	},
	{
		text: "Edit Business Hours",
		editItem: EditItem.BusinessHours,
	},
	{
		text: "Add Store Policy*",
		editItem: EditItem.ReturnPolicy,
	},
	{
		text: "Add delivery options*",
		editItem: EditItem.DeliveryOptions,
	},
]

const ListEntry = (props: Item) => {
	const { scrollTo } = useScrollToComponent()
	const formState = useAtomValue(builderErrorStateAtom)

	let icon = <CircleOutlined color="inherit" />

	switch (formState[props.editItem]) {
		case false:
			icon = <CheckCircleOutline color="success" />
			break
		case true:
			icon = <WarningAmberIcon color="error" />
			break
	}

	return (
		<MenuItem
			onClick={() => {
				scrollTo(props.editItem)
			}}
			divider
			sx={{
				padding: ".38rem 1rem",
			}}
		>
			<ListItemIcon>
				{icon}
			</ListItemIcon>
			<ListItemText
				primary={props.text}
				sx={{
					textWrap: "wrap",
					'.MuiListItemText-primary': {
						color: formState?.[props.editItem] === true ? palette.error.main : palette.common.black
					}
				}}
			/>
		</MenuItem>
	)
}

export const SetupGuide = () => {
	return (
		<>
			<Box paddingX={"1rem"}>
				<Alert severity="info" variant="standard" icon={false}>
					<Typography variant="body2">This guide takes you through the steps to launch your store.</Typography>
				</Alert>
			</Box>
			<Box px="1rem" py=".5rem">
				<Typography
					color="text.secondary"
					variant="body2"
					fontStyle="italic"
				>
					Steps marked <Box component="span" color="error.main">*</Box> are required
				</Typography>
			</Box>
			<MenuList>
				<MenuItem divider>
					<ListItemIcon>
						<CheckCircleOutline color="success" />
					</ListItemIcon>
					<ListItemText
						primary={"Create url"}
						sx={{
							textWrap: "wrap",
						}}
					/>
				</MenuItem>
				{items.map((item) => (
					<ListEntry key={item.text} {...item} />
				))}
			</MenuList>
		</>
	)
}

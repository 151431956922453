import { AddressComponent as Address, ContactNumber, Flag } from "@/builderComponents"
import TextField, { getMaxCharacterMutationFn, trim } from "@/components/TextField"
import { useContactValues } from "@/hooks"
import { builderErrorStateAtom, EditItem, formControlAtom } from "@/state"
import { Contact } from "@/types"
import Stack from "@mui/material/Stack"
import { useAtomValue, useSetAtom } from "jotai"
import React from "react"
import { Country } from "react-phone-number-input/input"

export const StoreDetails = () => {
	const { address: formAddress, email: formEmail, phone: formPhone, whatsapp: formWhatsapp } = useContactValues(true)
	const contact: Contact = { address: formAddress, email: formEmail, phone: formPhone, whatsapp: formWhatsapp }
	const { fieldErrors, handleOnChange } = useAtomValue(formControlAtom)
	const setErrorAtom = useSetAtom(builderErrorStateAtom)

	const maxEmailCharMutation = getMaxCharacterMutationFn(50)
	const trimMutation = trim

	const getValue = (value?: string): string => {
		return value ?? ""
	}

	const fieldHasError = (field: string) => {
		return fieldErrors.contact?.path === field
	}

	const errorMessage = fieldErrors.contact?.message

	const onPhoneChange = (phone: string, _flagIcon?: Flag, _countryCode?: string, _country?: string, _ISO?: Country): void => {
		handleOnChange("contact")({ ...contact, phone })
	}

	const onWhatsappChange = (
		whatsapp: string,
		_flagIcon?: Flag,
		_countryCode?: string,
		_country?: string,
		_ISO?: Country,
	): void => {
		handleOnChange("contact")({ ...contact, whatsapp })
	}

	React.useEffect(() => {
		setErrorAtom((prev) => ({
			...prev,
			[EditItem.StoreDetails]:
				fieldHasError("whatsapp") || fieldHasError("email") || fieldHasError("address") || fieldHasError("phone"),
		}))
	}, [fieldErrors.contact])

	return (
		<Stack marginTop={3} marginX={2} gap={3}>
			<Address initialValue={getValue(formAddress)} onChange={(address) => handleOnChange("contact")({ ...contact, address })} />
			<ContactNumber
				value={getValue(formPhone)}
				label={"Business phone number"}
				validate
				hasErrors={fieldHasError("phone")}
				validationText={errorMessage}
				required
				onChange={onPhoneChange}
			/>
			<ContactNumber
				value={getValue(formWhatsapp)}
				label={"WhatsApp cellphone number"}
				validate
				required={false}
				hasErrors={fieldHasError("whatsapp")}
				validationText={errorMessage}
				onChange={onWhatsappChange}
			/>
			<TextField
				value={getValue(formEmail)}
				required
				label="Business email address"
				helperText={fieldHasError("email") && errorMessage}
				error={fieldHasError("email")}
				changeMutationFns={[maxEmailCharMutation, trimMutation]}
				blurMutationFns={[trimMutation]}
				onChange={(e) => handleOnChange("contact")({ ...contact, email: e.target.value })}
			/>
		</Stack>
	)
}

import React from "react"
import ContentEditable, { ContentEditableEvent } from "react-contenteditable"

type EditableTextProps = {
	text: string
	onChange: (text: ContentEditableEvent) => void
	editingEnabled: boolean
	active: boolean
}
/**
 * Inline text editor that allows for editing text in place.\
 * Wrap this components with Typography for better styling. 💅🏼
 */
export const EditableText = (props: EditableTextProps) => {
	const ref = React.createRef<HTMLSpanElement>()

	const tagName = "span"

	// Because react-contenteditable loses focus when re-rendered,
	// we need to re-focus the element when it is re-rendered.
	React.useEffect(() => {
		if (props.active && ref.current) {
			console.debug("Focusing on editable text")
			ref.current.focus()
			ref.current.querySelector(tagName)?.focus()
		}
	}, [])

	return (
		<span ref={ref}>
			<ContentEditable
				html={props.text}
				onChange={props.onChange}
				disabled={!props.editingEnabled}
				tagName={tagName}
				style={{
					outline: "none", // Remove default focus outline
					margin: 0, // Ensure no extra margin
					padding: 0, // Ensure no extra padding
					fontFamily: "inherit", // Inherit font styles from Typography
					fontSize: "inherit",
					lineHeight: "inherit",
					color: "inherit",
					cursor: props.editingEnabled ? "text" : "default",
				}}
			/>
		</span>
	)
}

import { HeroContent } from "@/components/builder/ActionPanel/HeroContent"
import { SideMenuItem } from "@/enums"
import { useFinishComponentDraftEdit } from "@/hooks"
import { ActionType, EditItem, builderMenuReducerAtom } from "@/state"
import { alert } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import Close from "@mui/icons-material/Close"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { useAtom } from "jotai"
import React from "react"
import {
	About,
	BusinessHours,
	ReturnPolicy,
	SetupGuide,
	Socials,
	StoreColours,
	StoreDetails
} from "./ActionPanel"

export const SideMenuActionPanel = () => {
	const [menuState] = useAtom(builderMenuReducerAtom)
	const finishComponentDraftEdit = useFinishComponentDraftEdit()

	const dispatch = useAtom(builderMenuReducerAtom)[1]
	const handleCloseActionPanel = () => {
		dispatch({ type: ActionType.Close })
		finishComponentDraftEdit()
	}

	let component = SetupGuide
	let title = "Setup Guide"

	if (menuState.activeMenuItem === SideMenuItem.Settings) {
		switch (menuState.activeEditingItem) {
			case EditItem.HeroContent:
				component = HeroContent
				title = "Banner text"
				break
			case EditItem.AboutUs:
				component = About
				title = "About Us"
				break
			case EditItem.BusinessHours:
				component = BusinessHours
				title = "Trading Hours"
				break
			case EditItem.StoreDetails:
				component = StoreDetails
				title = "Store Details"
				break
			case EditItem.Socials:
				component = Socials
				title = "Socials"
				break
			case EditItem.ReturnPolicy:
				component = ReturnPolicy
				title = "Return Policy"
				break
		}
	}

	if (menuState.activeMenuItem === SideMenuItem.Colour) {
		component = StoreColours
		title = "Store Colours"
	}

	return (
		<Box
			sx={{
				height: "100%",
				overflowY: "auto",
				paddingTop: "1rem",
				width: "16.875rem",
				boxSizing: "border-box",
			}}
		>
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} margin={"1rem 0 1.5rem"} paddingX={"1rem"}>
				<Typography sx={alert.title}>{title}</Typography>
				<IconButton
					onClick={handleCloseActionPanel}
					sx={{
						width: "1.5rem",
						height: "1.5rem",
					}}
				>
					<Close />
				</IconButton>
			</Stack>
			{React.createElement(component)}
		</Box>
	)
}

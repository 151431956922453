import { theme } from "@ikhokha/commons-ui/build/dist/cjs/theme"
import { ThemeOptions } from "@mui/material/styles"
import cloneDeep from "lodash.clonedeep"
import merge from "lodash.merge"

const _templateTheme: ThemeOptions = {
	palette: {
		primary: {
			main: "#312C1A",
		},
		secondary: {
			main: "#F8F8F7",
		},
	},
}

export const grey = merge(cloneDeep(theme), _templateTheme)

import IkBrandLogoYellow from "@/assets/IkBrandLogo-yello"
import SaveState from "@/components/SaveState"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"

export const NavbarBack = ({ showSaveState }: { showSaveState?: boolean }) => {
	return (
		<Stack
			direction={"row"}
			alignItems={"center"}
			justifyContent={"flex-start"}
			gap={"1rem"}
			sx={{
				color: "#000",
			}}
		>
			<Tooltip title="Back to dashboard" placement="right-start" arrow>
				<Link underline="none" href={process.env.NEXT_PUBLIC_MERCHANT_DASHBOARD_REDIRECT}>
					<IconButton>
						<IkBrandLogoYellow width={32} height={32} />
					</IconButton>
				</Link>
			</Tooltip>
			{showSaveState && <SaveState />}
		</Stack>
	)
}

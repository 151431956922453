import { IkhokhaThemeProvider } from "@/components/builder"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { PencilSimple } from "@phosphor-icons/react"
import React from "react"

export const getWrapperOverlay = (active: boolean, handleEditClick: (element: HTMLElement) => void, buttonText = "Edit") => () => {
	const ref = React.createRef<HTMLDivElement>()

	return (
		<IkhokhaThemeProvider>
			<Box sx={{ height: "100%" }} ref={ref}>
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						width: "100%",
						height: "100%",
						backgroundColor: active ? "transparent" : "rgba(0, 158, 172, 0.08)",
						zIndex: 2,
						padding: ".625rem",
						boxSizing: "border-box",
					}}
				>
					{!active && (
						<Button
							startIcon={<PencilSimple />}
							size="small"
							variant="contained"
							sx={{
								textTransform: "none",
							}}
							onClick={() => handleEditClick(ref.current!)}
						>
							{buttonText}
						</Button>
					)}
				</Box>
			</Box>
		</IkhokhaThemeProvider>
	)
}

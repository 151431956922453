import { motion } from "framer-motion"

export const Fade = (props: React.PropsWithChildren) => (
	<motion.span
		initial={{ opacity: 0, scale: 0.8 }}
		animate={{ opacity: 1, scale: 1 }}
		exit={{ opacity: 0, scale: 0.8 }}
		transition={{ duration: 0.4 }}
	>
		{props.children}
	</motion.span>
)

import MUITextField, { TextFieldProps as MUITextFieldProps } from "@mui/material/TextField"
import React from "react"

type MutionFn = (value: string) => string

type MutationFns = Array<MutionFn>

export const getMaxCharacterMutationFn = (maxCharacterCount: number) => (value: string) => {
	if (value.length > maxCharacterCount) {
		return value.slice(0, maxCharacterCount)
	}

	return value
}

export const trim = (value: string) => {
	return value.trim()
}

const applyMutationFns = (value: string, fns: MutationFns = []) => {
	return fns.reduce((acc, fn) => fn(acc), value)
}

const getHandleOnChangeMutationFn =
	(
		changeMutations: MutationFns = [],
		onChange?: React.ChangeEventHandler<HTMLInputElement>,
	): React.ChangeEventHandler<HTMLInputElement> =>
	(event) => {
		const _event = { ...event }
		const {
			target: { value },
		} = _event

		const mutatedValue = applyMutationFns(value, changeMutations)
		_event.target.value = mutatedValue

		onChange?.(_event)
	}

const getHandleOnBlurMutationFn =
	(
		changeMutations: MutationFns = [],
		onBlur?: React.FocusEventHandler<HTMLInputElement>,
	): React.FocusEventHandler<HTMLInputElement> =>
	(event) => {
		const _event = { ...event }
		const {
			target: { value },
		} = _event

		const mutatedValue = applyMutationFns(value, changeMutations)
		_event.target.value = mutatedValue

		onBlur?.(_event)
	}

type TextFieldProps = MUITextFieldProps & {
	changeMutationFns?: MutationFns
	blurMutationFns?: MutationFns
	required?: boolean
}
function TextField(props: TextFieldProps) {
	const _handleOnBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
		// Because we mutate the value only on blur, we need to call onChange here
		// for the mutated value to be reflected.
		props.onChange?.(event)
		props.onBlur?.(event)
	}

	const { changeMutationFns, blurMutationFns, ...rest } = props

	const onChange = getHandleOnChangeMutationFn(changeMutationFns, props.onChange)
	const onBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
		getHandleOnBlurMutationFn(blurMutationFns, _handleOnBlur)(event)
	}

	return <MUITextField {...rest} onChange={onChange} onBlur={onBlur} />
}

export default TextField

import { WebstoreConfigurationColor } from "@/enums"
import { colourThemeAtom } from "@/state"
import { blue, green, grey, purple, white, yellow } from "@/templateThemes"
import { ThemeProvider } from "@mui/material/styles"
import { useAtomValue } from "jotai"

type Props = {
	themeName?: WebstoreConfigurationColor
}

const getTheme = (themeName: WebstoreConfigurationColor) => {
	switch (themeName) {
		case WebstoreConfigurationColor.BLUE:
			return blue
		case WebstoreConfigurationColor.GREEN:
			return green
		case WebstoreConfigurationColor.GREY:
			return grey
		case WebstoreConfigurationColor.PURPLE:
			return purple
		case WebstoreConfigurationColor.YELLOW:
			return yellow
		default:
			return white
	}
}

/**
 * The themeName prop should be used when rendering the template to the public.\
 * Defaults to the builder selected theme if no themeName is provided.
 * @param props
 * @returns
 */
const TemplateComponentThemeProvider = (props: React.PropsWithChildren<Props>) => {
	const builderSelectedTheme = useAtomValue(colourThemeAtom)

	const theme = props.themeName ? props.themeName : builderSelectedTheme ?? WebstoreConfigurationColor.WHITE

	return <ThemeProvider theme={getTheme(theme)}>{props.children}</ThemeProvider>
}

export default TemplateComponentThemeProvider
